import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";

// plug-ins
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import moment from 'moment';
import 'moment/locale/ru';

// components
import Template from '../../Components/Template';

// helpers
import { ls } from '../../Globals/Localstorage';

import { countsGet, moneyFormat } from '../../Globals/Utils';

// globals
import { SETTINGS } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import cardVisa from './Images/cards/visa.svg';
import cardMastercard from './Images/cards/mastercard.svg';
import cardMir from './Images/cards/mir.svg';
import cardYouMoney from './Images/cards/youmoney.svg';
import step1 from './Images/steps/phone.svg';
import step2 from './Images/steps/invoice.svg';
import step3 from './Images/steps/wallet.svg';
import adv1 from './Images/advantages/clock.svg';
import adv2 from './Images/advantages/calendar.svg';
import adv3 from './Images/advantages/security.svg';
import adv4 from './Images/advantages/24-hours.svg';
import adv5 from './Images/advantages/discount.svg';
import adv6 from './Images/advantages/like.svg';
import arrowUp from './Images/arrow-up.svg';
import arrowDown from './Images/arrow-down.svg';

const StartScreen = () => {
	const history = useHistory();
	const [amount, setAmount] = useState(SETTINGS.credit.amount.default);
	const [amountMin, setAmountMin] = useState(SETTINGS.credit.amount.min);
	const [amountMax, setAmountMax] = useState(SETTINGS.credit.amount.max);
	const [period, setPeriod] = useState(SETTINGS.credit.period.default);
	const [periodMin, setPeriodMin] = useState(SETTINGS.credit.period.min);
	const [periodMax, setPeriodMax] = useState(SETTINGS.credit.period.max);
	const [total, setTotal] = useState(0);
	const [accordion, setAccordion] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const dataGet = async () => {
			calc(amount, period);
			setLoading(false);
		};
		dataGet();
	}, []);
	const sliderPeriodSet = (e) => {
		const period = e[1];
		setPeriod(period);
		calc(amount, period);
	};
	const sliderAmountSet = (e) => {
		const amount = e[1];
		setAmount(amount);
		calc(amount, period);
	};
	const calc = (amount, period) => {
		const rate = SETTINGS.credit.rate;
		const quantity = 1;
		const total = Math.round((rate * period * amount / 100 + amount * 1) / quantity);
		setTotal(total);
	}
	const isAccordionOpen = (id) => {
		const a = accordion ? accordion.find(f => f.id === id) : null;
		return a ? a.isopen : false;
	}
	const accordionSet = (id) => {
		if (accordion.find(f => f.id === id)) {
			accordion.forEach((v) => {
				if (v.id === id) {
					if (v.isopen) v.isopen = false;
					else v.isopen = true;
				}
			});
			setAccordion([...accordion]);
		} else setAccordion([...accordion,{id,isopen:true}]);
	}
	const moneyGet = () => {
		const data = {amount,period,total};
		ls('order', data);
		history.push('/anketa');
	}
	return loading ? null : <Template bgStyle={{height:'calc(100vh - 20vh + 120px)'}}>
		<section className="start">
			<div className="start-form">
				<div className="start-slogan">
					<h1>Быстрые займы <span>онлайн</span></h1>
					<div className="start-slogan-note">Бесплатно для новых клиентов<br/>при погашении займа в срок</div>
				</div>
				<ul>
					<li>Заполните заявку на сайте</li>
					<li>Узнайте решение через несколько минут</li>
					<li>Получите деньги на свою карту</li>
				</ul>
				<div className="start-form-container">
					<div className="start-form-title">Первый займ без процентов</div>
					<div className="start-form-title-note">Первый заем — до 15 000 ₽</div>
					<div className="start-form-row">
						<span>{moneyFormat(amount, false)} ₽</span>
					</div>
					<div className="start-form-row-slider start-form-row-slider-separator">
						<RangeSlider
							id="rs-amount"
							min={amountMin}
							max={amountMax}
							step={SETTINGS.credit.amount.step}
							thumbsDisabled={[true,false]}
							rangeSlideDisabled={true}
							onInput={sliderAmountSet}
							defaultValue={[0,amount]} />
						<div className="start-form-row-slider-info">
							<span>{moneyFormat(amountMin, false)} ₽</span>
							<span>{moneyFormat(amountMax, false)} ₽</span>
						</div>
					</div>
					<div className="start-form-row-slider">
						<div className="start-form-row-slider-info">
							<span>На срок <b>{countsGet.days(period)}</b></span>
							<span>{moment().add(period, 'days').format('ll')}</span>
						</div>
						<RangeSlider
							id="rs-period"
							min={periodMin}
							max={periodMax}
							step={SETTINGS.credit.period.step}
							thumbsDisabled={[true,false]}
							rangeSlideDisabled={true}
							onInput={sliderPeriodSet}
							defaultValue={[0,period]} />
						<div className="start-form-row-slider-info">
							<span>{countsGet.days(periodMin)}</span>
							<span>{countsGet.days(periodMax)}</span>
						</div>
					</div>
					<div className="start-form-summary">
						<div>
							<span><b>Займ</b></span>
							<span>{moneyFormat(amount, false)} ₽</span>
						</div>
						<div>
							<span><b>К возврату</b></span>
							<span>{moneyFormat(total, false)} ₽</span>
						</div>
					</div>
					<button onClick={() => moneyGet()}>Получить деньги</button>
					<div className="start-form-notice">
						<span>{SETTINGS.credit.rate}% в день от {moneyFormat(amountMin, false)} ₽ на срок от {countsGet.days(periodMin)}.</span>
						<span>Индивидуальные условия. Возможно досрочное погашение с оплатой только за дни пользования займом.</span>
					</div>
				</div>
			</div>
			<div className="start-mascotte">
				<div className="start-mascotte-container">
					<img src={require('./Images/Capitalina.png')} alt="Капиталина" />
				</div>
				<div className="start-payment-container">
					<div className="start-payment-title">Удобно получать и погашать займы</div>
					<ul>
						<li><img src={cardVisa} alt="Visa" /></li>
						<li><img src={cardMastercard} alt="Mastercard" /></li>
						<li><img src={cardMir} alt="Mir" /></li>
						<li><img src={cardYouMoney} alt="Ю-Money" /></li>
					</ul>
				</div>
			</div>
		</section>
		<section className="start-steps">
			<h2>Как получить займ?</h2>
			<ul>
				<li>
					<img src={step1} alt="1" />
					<b>1. Заполните анкету</b>
					<span>Паспортные данные, номер телефона</span>
				</li>
				<li>
					<img src={step2} alt="1" />
					<b>2. Ожидайте ответа</b>
					<span>Вы получите e-mail и SMS</span>
				</li>
				<li>
					<img src={step3} alt="1" />
					<b>3. Получите мгновенно</b>
					<span>На банковскую карту или ЮMoney</span>
				</li>
			</ul>
		</section>
		<section className="start-advantages">
			<div className="start-advantages-title">
				<h2>Преимущества<br/>получить займ<br/>в нашей компании<span>90% <b>одобрений</b></span></h2>
			</div>
			<div className="start-advantages-info">
				<ul>
					<li>
						<img src={adv1} alt="" />
						<span>Деньги за 1 минуту после одобрения</span>
					</li>
					<li>
						<img src={adv2} alt="" />
						<span>Продление без штрафов</span>
					</li>
					<li>
						<img src={adv3} alt="" />
						<span>Безопасность персональных данных</span>
					</li>
					<li>
						<img src={adv4} alt="" />
						<span>Погашение займа 24 часа без комиссии</span>
					</li>
					<li>
						<img src={adv5} alt="" />
						<span>Премиум услуги для постоянных клиентов</span>
					</li>
					<li>
						<img src={adv6} alt="" />
						<span>Улучшение кредитной истории</span>
					</li>
				</ul>
			</div>
		</section>
		<section className="start-questions">
			<h2>Срочные онлайн займы в <span>Capitalina</span></h2>
			<div className="start-questions-info">
				<p>
					У вас возникли финансовые затруднения? Мы поможем взять быстрый микрозайм через интернет под 1% в день.<br/>
					Вы получите необходимую денежную сумму, а обращение останется конфиденциальным.
				</p>
				<p>Для того, чтобы подать заявку в микрофинансовую компанию, вам не нужны справки о доходе, копии документов или поручители.</p>
				<p>Capitalina finance упрощает процедуру онлайн получения денег. Анкеты заемщиков рассматривает автоматическая система, что исключает риск человеческой ошибки и утечки данных. Деньги будут отправлены вам уже через 1 минуту после принятия положительного решения!</p>
				<p>В нашей системе предусмотрена срочная обработка переводов и платежей. Помните, что сроки и порядок зачисления средств на Ваш счет зависит только от банка, выпустившего вашу карту.</p>
			</div>
			<div className="accordion">
				<div className="accordion-item">
					<div className={`accordion-title ${isAccordionOpen(1) ? 'accordion-title-select': ''}`} onClick={() => accordionSet(1)}>
						<span>Как получить заем?</span>
						<img src={isAccordionOpen(1) ? arrowUp : arrowDown} alt="" />
					</div>
					{isAccordionOpen(1) ?
						<div className="accordion-content">
							<p>Для получения займа вам необходимо:</p>
							<ul>
								<li>Заполните анкету в личном кабинете: Приготовьте паспорт и СНИЛС для заполнения онлайн-анкеты. Дождитесь подтверждения идентификации</li>
								<li>Дождитесь одобрения заявки</li>
								<li>Получите деньги на свою карту: После положительного решения, мы переводим деньги на вашу карту сразу. В некоторых случаях процесс поступления может составлять всего несколько часов.</li>
							</ul>
						</div> : null}
				</div>
				<div className="accordion-item">
					<div className={`accordion-title ${isAccordionOpen(2) ? 'accordion-title-select': ''}`} onClick={() => accordionSet(2)}>
						<span>Какие условия выдачи займа?</span>
						<img src={isAccordionOpen(2) ? arrowUp : arrowDown} alt="" />
					</div>
					{isAccordionOpen(2) ?
						<div className="accordion-content">
							<p>Из основного — вам должно быть 18 лет или больше. Вам нужен паспорт гражданина РФ и СНИЛС. Правила предоставления займов, условия, типовой договор опубликованы на <a href="/documenti" target="_blank">capitalina.ru</a></p>
						</div> : null}
				</div>
				<div className="accordion-item">
					<div className={`accordion-title ${isAccordionOpen(3) ? 'accordion-title-select': ''}`} onClick={() => accordionSet(3)}>
						<span>Какие нужны документы?</span>
						<img src={isAccordionOpen(3) ? arrowUp : arrowDown} alt="" />
					</div>
					{isAccordionOpen(3) ?
						<div className="accordion-content">
							<p>Достаточно паспорта гражданина РФ и СНИЛС</p>
						</div> : null}
				</div>
				<div className="accordion-item">
					<div className={`accordion-title ${isAccordionOpen(4) ? 'accordion-title-select': ''}`} onClick={() => accordionSet(4)}>
						<span>Какие требования предъявляются к заемщику?</span>
						<img src={isAccordionOpen(4) ? arrowUp : arrowDown} alt="" />
					</div>
					{isAccordionOpen(4) ?
						<div className="accordion-content">
							<p>Ваша заявка с большой вероятностью будет одобрена, если: Ваш возраст не менее 18 лет; У вас есть российское гражданство; Вы правильно указали телефоны и информацию о себе в онлайн-анкете.</p>
						</div> : null}
				</div>
				<div className="accordion-item">
					<div className={`accordion-title ${isAccordionOpen(5) ? 'accordion-title-select': ''}`} onClick={() => accordionSet(5)}>
						<span>У меня были просрочки по займам. Могу ли я получить займ?</span>
						<img src={isAccordionOpen(5) ? arrowUp : arrowDown} alt="" />
					</div>
					{isAccordionOpen(5) ?
						<div className="accordion-content">
							<p>Каждая заявка рассматривается индивидуально. На решение по займу влияет множество факторов. Немаловажное значение имеет ваша кредитная история.</p>
						</div> : null}
				</div>
				<div className="accordion-item">
					<div className={`accordion-title ${isAccordionOpen(6) ? 'accordion-title-select': ''}`} onClick={() => accordionSet(6)}>
						<span>Нужны ли для получения займа залог или поручительство?</span>
						<img src={isAccordionOpen(6) ? arrowUp : arrowDown} alt="" />
					</div>
					{isAccordionOpen(6) ?
						<div className="accordion-content">
							<p>Мы выдаем займы без залога и поручительства.</p>
						</div> : null}
				</div>
				<div className="accordion-item">
					<div className={`accordion-title ${isAccordionOpen(7) ? 'accordion-title-select': ''}`} onClick={() => accordionSet(7)}>
						<span>Льготный период для мобилизованных и участников СВО</span>
						<img src={isAccordionOpen(7) ? arrowUp : arrowDown} alt="" />
					</div>
					{isAccordionOpen(7) ?
						<div className="accordion-content">
							<p>Основания для предоставления льготного периода по договору потребительского займа.</p>
							<p>Федеральный закон №377-ФЗ от 07.10.2022 года <a href="https://capitalina.ru/files/377.pdf" target="_blank">скачать</a>.</p>
							<p>Условия для получения льготного периода <a href="https://capitalina.ru/files/Mob%20(2).pdf" target="_blank">скачать</a>.</p>
						</div> : null}
				</div>
			</div>
		</section>
	</Template>;
};

export default StartScreen;