import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const addressGet	= async (address)		=> Http.request('ext/refs/address', httpMethods.POST, {address});

const checker		= async (type, query)	=> Http.request('ext/refs/checker', httpMethods.POST, {type,query});
const checkerData	= async (data)	=> Http.request('ext/refs/checker', httpMethods.POST, data);


export {
	addressGet,
	checker,
	checkerData
}