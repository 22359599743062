import React, {useEffect,useState} from 'react';

// plug-ins
import InputMask from 'react-input-mask';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import moment from 'moment';
import 'moment/locale/ru';

// components
import Template from '../../Components/Template';
import {useInput} from '../../Components/Hooks/Input';
import Toaster from '../../Components/Toaster';

// models
import {Refs,Requests} from '../../Models/';

// helpers
import { countsGet, empty, moneyFormat, phoneNormalize } from '../../Globals/Utils';
import { ls } from '../../Globals/Localstorage';

// globals
import { API, SETTINGS, userContactType, userContactTypeName, userEmployment, userEmploymentName, userGender, userGenderName } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import loader from './Images/loader.svg';

const AnketaScreen = () => {
	const [id, setId] = useState(0);
	const [step, setStep] = useState(1);
	const [stepMax, setStepMax] = useState(7);
	const [inputs, setInputs] = useState({});
	const [request, setRequest] = useState(null);
	const [amount, setAmount] = useState(SETTINGS.credit.amount.default);
	const [amountMin, setAmountMin] = useState(SETTINGS.credit.amount.min);
	const [amountMax, setAmountMax] = useState(SETTINGS.credit.amount.max);
	const [period, setPeriod] = useState(SETTINGS.credit.period.default);
	const [periodMin, setPeriodMin] = useState(SETTINGS.credit.period.min);
	const [periodMax, setPeriodMax] = useState(SETTINGS.credit.period.max);
	const [amountCredit, setAmountCredit] = useState(0);
	const {value:firstName, setValue:setFirstName, bind:bindFirstName} = useInput('');
	const {value:lastName, setValue:setLastName, bind:bindLastName} = useInput('');
	const {value:middleName, setValue:setMiddleName, bind:bindMiddleName} = useInput('');
	const {value:birthDate, setValue:setBirthDate, bind:bindBirthDate} = useInput('');
	const {value:birthPlace, setValue:setBirthPlace, bind:bindBirthPlace} = useInput('');
	const {value:passportSeries, setValue:setPassportSeries, bind:bindPassportSeries} = useInput('');
	const {value:passportNumber, setValue:setPassportNumber, bind:bindPassportNumber} = useInput('');
	const {value:passportIssuer, setValue:setPassportIssuer, bind:bindPassportIssuer} = useInput('');
	const {value:passportDate, setValue:setPassportDate, bind:bindPassportDate} = useInput('');
	const {value:passportCode, setValue:setPassportCode, bind:bindPassportCode} = useInput('');
	const {value:INN, setValue:setINN, bind:bindINN} = useInput('');
	const {value:SNILS, setValue:setSNILS, bind:bindSNILS} = useInput('');
	const {value:incomeAverage, setValue:setIncomeAverage, bind:bindIncomeAverage} = useInput('');
	const {value:workName, setValue:setWorkName, bind:bindWorkName} = useInput('');
	const {value:workPosition, setValue:setWorkPosition, bind:bindWorkPosition} = useInput('');
	const {value:workAddress, setValue:setWorkAddress, bind:bindWorkAddress} = useInput('');
	const {value:workPhone, setValue:setWorkPhone, bind:bindWorkPhone} = useInput('');
	const {value:contactFirstName, setValue:setContactFirstName, bind:bindContactFirstName} = useInput('');
	const {value:contactLastName, setValue:setContactLastName, bind:bindContactLastName} = useInput('');
	const {value:contactMiddleName, setValue:setContactMiddleName, bind:bindContactMiddleName} = useInput('');
	const {value:contactPhone, setValue:setContactPhone, bind:bindContactPhone} = useInput('');
	const {value:creditCardNumber, setValue:setCreditCardNumber, bind:bindCreditCardNumber} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:email, setValue:setEmail, bind:bindEmail} = useInput('');
	const {value:SMSCode, setValue:setSMSCode, bind:bindSMSCode} = useInput('');
	const [agree, setAgree] = useState(false);
	const [workType, setWorkType] = useState(userEmployment.UNSET);
	const [contactType, setContactType] = useState(userContactType.UNSET);
	const [gender, setGender] = useState(userGender.UNSET);
	const [photoPassportSelfie, setPhotoPassportSelfie] = useState(null);
	const [photoPassportRegistration, setPhotoPassportRegistration] = useState(null);
	const [photoPassportFirst, setPhotoPassportFirst] = useState(null);
	const [photoCard, setFileCard] = useState(null);
	const [addressRegistration, setAddressRegistration] = useState('');
	const [addressLive, setAddressLive] = useState('');
	const [addressReistrationList, setAddressReistrationList] = useState();
	const [addressLiveList, setAddressLiveList] = useState();
	const [isSend, setIsSend] = useState(false);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const dataGet = async () => {
			const order = ls('order') || null;
			if (order !== null) {
				setAmount(order.amount);
				setPeriod(order.period);
				calc(order.amount, order.period);
			}
			setLoading(false);
		};
		dataGet();
	}, []);
	const checkHandleAgree = (e) => setAgree(e.target.checked);
	const radioHandleGender = (e) => setGender(parseInt(e.target.value));
	const selectHandleWorkType = (e) => setWorkType(parseInt(e.target.value));
	const selectHandleContactType = (e) => setContactType(parseInt(e.target.value));
	const handlePhoto = (e, type) => {
		const file = e.target.files?.[0];
		if (!file) {
			if (type === 'selfie') setPhotoPassportSelfie(null);
			if (type === 'registration') setPhotoPassportRegistration(null);
			if (type === 'passportFirst') setPhotoPassportFirst(null);
			if (type === 'card') setFileCard(null);
			return;
		}
		const reader = new FileReader();
		reader.onload = (r) => {
			if (type === 'selfie') setPhotoPassportSelfie(r.target.result);
			if (type === 'registration') setPhotoPassportRegistration(r.target.result);
			if (type === 'passportFirst') setPhotoPassportFirst(r.target.result);
			if (type === 'card') setFileCard(r.target.result);
		};
		reader.readAsDataURL(file);
	};
	const fieldNameGet = (field) => Object.keys(field)[0];
	const validator = async (field, fieldName, iserror, conditions) => {
		if (!field && conditions[0].dontCheck === undefined) {
			if (!iserror) {
				Toaster.show({message:conditions[0].message,istitle:true});
				inputs[fieldName]?.focus();
			}
			inputs[fieldName]?.classList.add('error');
			iserror = true;
		} else {
			if (conditions[1]) {
				if (conditions[1].regexp) {
					if (!conditions[1].regexp.test(field)) {
						if (!iserror) {
							Toaster.show({message:conditions[1].message,istitle:true});
							inputs[fieldName]?.focus();
						}
						inputs[fieldName]?.classList.add('error');
						iserror = true;
					} else inputs[fieldName]?.classList.remove('error');
				}
				if (conditions[1].expression !== undefined) {
					const ex = await conditions[1].expression();
					if (empty(ex)) {
						if (!iserror) {
							if (conditions[1].message)
								Toaster.show({message:conditions[1].message,istitle:true});
							inputs[fieldName]?.focus();
						}
						inputs[fieldName]?.classList.add('error');
						iserror = true;
					} else inputs[fieldName]?.classList.remove('error');
				}
			} else inputs[fieldName]?.classList.remove('error');
		}
		return iserror;
	}
	const nextSet = async () => {
		let iserror = false;
		if (step === 1) {
			iserror = await validator(lastName, fieldNameGet({lastName}), iserror, [{message:'Необходимо указать фамилию'},{regexp:/^[а-яё]*$/gi,message:'Фамилия должна содержать только буквы русского алфавита'}]);
			iserror = await validator(firstName, fieldNameGet({firstName}), iserror, [{message:'Необходимо указать имя'},{regexp:/^[а-яё]*$/gi,message:'Имя должно содержать только буквы русского алфавита'}]);
			iserror = await validator(middleName, fieldNameGet({middleName}), iserror, [{dontCheck:true},{regexp:/^[а-яё]*$/gi,message:'Отчество должно содержать только буквы русского алфавита'}]);
			iserror = await validator(birthDate, fieldNameGet({birthDate}), iserror, [{message:'Необходимо указать дату рождения'}]);
			iserror = await validator(birthPlace, fieldNameGet({birthPlace}), iserror, [{message:'Необходимо указать место рождения'}]);
			if (gender === userGender.UNSET) {
				if (!iserror) Toaster.show({message:'Необходимо указать пол',istitle:true});
				iserror = true;
			}
			iserror = await validator(passportSeries, fieldNameGet({passportSeries}), iserror, [{message:'Необходимо указать серию паспорта'},{regexp:/^\d{2} \d{2}$/gi,message:'Серия паспорта должна содержать 4 цифры'}]);
			iserror = await validator(passportNumber, fieldNameGet({passportNumber}), iserror, [{message:'Необходимо указать номер паспорта'},{regexp:/^\d{6}$/gi,message:'Номер паспорта должен содержать 6 цифр'}]);
			iserror = await validator(passportIssuer, fieldNameGet({passportIssuer}), iserror, [{message:'Необходимо указать кем выдан паспорт'}]);
			iserror = await validator(passportDate, fieldNameGet({passportDate}), iserror, [{message:'Необходимо указать дату выдачи паспорта'}]);
			iserror = await validator(passportCode, fieldNameGet({passportCode}), iserror, [{message:'Необходимо указать код подразделения'}]);
		}
		if (step === 2) {
			iserror = await validator(INN, fieldNameGet({INN}), iserror, [{message:'Необходимо указать ИНН'},
				{expression: async () => {
					const inn = await Refs.checker('inn', INN).catch((ex) => console.log(666, ex));
					if (inn) {
						const res = await Refs.checkerData({type:'inn_find',firstName,lastName,middleName,birthDate,passportSeries,passportNumber,passportDate}).catch((ex) => console.log(666, ex));
						return res && res.inn === inn;
					}
				},message:'Неверный ИНН!'}]);
			//iserror = await validator(INN, fieldNameGet({INN}), iserror, [{message:'Необходимо указать ИНН'},
			//	{expression: async () => await Refs.checker('inn', INN).catch((ex) => console.log(666, ex)),message:'Неверный ИНН!'}]);
			iserror = await validator(SNILS, fieldNameGet({SNILS}), iserror, [{message:'Необходимо указать СНИЛС'},
				{expression: async () => await Refs.checker('snils', SNILS).catch((ex) => console.log(666, ex)),message:'Неверный СНИЛС!'}]);
			iserror = await validator(addressRegistration, fieldNameGet({addressRegistration}), iserror, [{message:'Необходимо указать адрес регистрации'}]);
			iserror = await validator(addressLive, fieldNameGet({addressLive}), iserror, [{message:'Необходимо указать адрес проживания'}]);
		}
		if (step === 5) {
			iserror = await validator(creditCardNumber, fieldNameGet({creditCardNumber}), iserror, [{message:'Необходимо указать номер карты'},
				{expression: async () => await Refs.checker('card', creditCardNumber).catch((ex) => console.log(666, ex)),message:'Неверный номер карты!'}]);
			iserror = await validator(phone, fieldNameGet({phone}), iserror, [{message:'Необходимо указать номер мобильного телефона'},
				{expression: async () => await Refs.checker('phone', phone).catch((ex) => console.log(666, ex)),message:'Неверный номер мобильного телефона!'}]);
		}
		if (step === 6) {
			if (!photoPassportSelfie) {
				Toaster.show({message:'Необходимо добавить фотографию с паспортом',istitle:true});
				return;
			}
			if (!photoPassportFirst) {
				Toaster.show({message:'Необходимо добавить фотографию паспорта',istitle:true});
				return;
			}
			if (!photoPassportRegistration) {
				Toaster.show({message:'Необходимо добавить фотографию паспорта с регистрацией',istitle:true});
				return;
			}
			if (!photoCard) {
				Toaster.show({message:'Необходимо добавить фотографию карты',istitle:true});
				return;
			}
			if (!agree) {
				Toaster.show({message:'Для продолжения необходимо принять соглашение',istitle:true});
				return;
			}
			requestCreate();
			return;
		}
		if (step === 7) {
			iserror = await validator(SMSCode, fieldNameGet({SMSCode}), iserror, [{message:'Необходимо указать код из СМС'},
				{expression: async () => await requestSend()}]);
			return;
		}
		iserror = await iserror;
		if (!iserror) setStep(step+1);
		setTimeout(toTop, 100);
	};
	const prevSet = (step) => {
		setSMSCode('');
		setStep(step);
		setTimeout(toTop, 100);
	}
	const toTop = () => window.scrollTo(0, 260);
	const sliderPeriodSet = (e) => {
		const period = e[1];
		setPeriod(period);
		calc(amount, period);
	};
	const sliderAmountSet = (e) => {
		const amount = e[1];
		setAmount(amount);
		calc(amount, period);
	};
	const calc = (amount, period) => {
		const rate = SETTINGS.credit.rate;
		const quantity = 1;
		const amountCredit = Math.round((rate * period * amount / 100 + amount * 1) / quantity);
		setAmountCredit(amountCredit);
	};
	const requestCreate = async () => {
		const request = {
			firstName,
			lastName,
			middleName,
			gender,
			birthDate,
			birthPlace,
			addressLive,
			addressRegistration,
			passportCode,
			passportDate,
			passportIssuer,
			passportNumber,
			passportSeries,
			INN,
			SNILS,
			phone:phoneNormalize(phone),
			email,
			creditCardNumber,
			amount,
			period,
			amountCredit,
			percent:SETTINGS.credit.rate,
			incomeAverage,
			workType,
			workName,
			workPosition,
			workAddress,
			workPhone:phoneNormalize(workPhone),
			contactType,
			contactFirstName,
			contactLastName,
			contactMiddleName,
			contactPhone:phoneNormalize(contactPhone),
			photoPassportSelfie,
			photoPassportFirst,
			photoPassportRegistration,
			photoCard
		};
		const res = await Requests.check(request).catch((ex) => {
			console.log(666, ex);
			Toaster.show({message:ex.message||'Ошибка отправки данных',istitle:true});
		});
		if (res) {
			setRequest(request);
			setStep(7);
			setTimeout(() => inputs.SMSCode?.focus(), 500);
		}
	}
	const requestSend = async () => {
		setStep(8);
		setIsSend(true);
		setTimeout(async () => {
			request.code = SMSCode;
			const res = await Requests.add(request).catch((ex) => {
				console.log(666, ex);
				Toaster.show({message:ex.message||'Ошибка отправки данных',istitle:true});
				setStep(7);
				setTimeout(() => {
					inputs.SMSCode?.focus();
					inputs.SMSCode?.classList.add('error');
				}, 100);
			});
			if (res) setId(res.id);
			setIsSend(false);
		}, 2000);
	};
	const toPersonal = () => window.location.href = `${API.lkAuth}/${id}${phoneNormalize(phone)}${INN}`;
	const addressCheck = async (e, setter, type) => {
		const address = e.target.value;
		setter(address);
		if (address.length < 5) {
			addressListClear();
			return;
		}
		const res = await Refs.addressGet(address).catch((ex) => {
			console.log(666, ex);
			Toaster.show({message:ex.message||'Ошибка отправки данных',istitle:true});
		});
		if (res && res.length && res[0].result) {
			if (type === 'registartion') setAddressReistrationList(res);
			if (type === 'live') setAddressLiveList(res);
		}
	};
	const addressSelect = (address, setter) => {
		setter(address);
		addressListClear();
	}
	const addressListClear = () => {
		setAddressReistrationList(null);
		setAddressLiveList(null);
	}
	return loading ? null : <Template bgStyle={{height:'500px'}}>
		<section className="anketa-container">
			<h1>Регистрация для получения<br/>первого займа</h1>
			<div className="anketa-container-inner">
			{step === 1 ?
				<>
					<h3>Основные данные</h3>
					<div className="anketa-row">
						<InputMask mask={Array.from({length:30}, () => /^[а-яё]$/gi)} placeholder="Фамилия" maskPlaceholder={null} {...bindLastName} ref={r => inputs.lastName = r} />
					</div>
					<div className="anketa-row">
						<InputMask mask={Array.from({length:30}, () => /^[а-яё]$/gi)} placeholder="Имя" maskPlaceholder={null} {...bindFirstName} ref={r => inputs.firstName = r} />
					</div>
					<div className="anketa-row">
						<InputMask mask={Array.from({length:30}, () => /^[а-яё]$/gi)} placeholder="Отчество" maskPlaceholder={null} {...bindMiddleName} ref={r => inputs.middleName = r} />
					</div>
					<div className="anketa-row-oneline">
						<div className="anketa-row">
							<input type="date" {...bindBirthDate} placeholder="Дата рождения" ref={r => inputs.birthDate = r} />
						</div>
						<div className="anketa-row">
							<InputMask mask={Array.from({length:30}, () => /^[а-яё]$/gi)} placeholder="Место рождения" maskPlaceholder={null} {...bindBirthPlace} ref={r => inputs.birthPlace = r} />
						</div>
					</div>
					<div className="anketa-row">
						<div>
							<label>
								<input type="radio" onChange={radioHandleGender} value={userGender.MALE} checked={gender===userGender.MALE} /> {userGenderName[userGender.MALE]}
							</label>
						</div>
						<div>
							<label>
								<input type="radio" onChange={radioHandleGender} value={userGender.FEMALE} checked={gender===userGender.FEMALE} /> {userGenderName[userGender.FEMALE]}
							</label>
						</div>
					</div>
					<h3>Паспорт</h3>
					<div className="anketa-row-oneline">
						<div className="anketa-row">
							<InputMask mask="99 99" placeholder="Серия" maskPlaceholder={null} {...bindPassportSeries} ref={r => inputs.passportSeries = r} />
						</div>
						<div className="anketa-row">
							<InputMask mask="999999" placeholder="Номер" maskPlaceholder={null} {...bindPassportNumber} ref={r => inputs.passportNumber = r} />
						</div>
					</div>
					<div className="anketa-row">
						<input type="text" {...bindPassportIssuer} placeholder="Кем выдан" ref={r => inputs.passportIssuer = r} />
					</div>
					<div className="anketa-row-oneline">
						<div className="anketa-row">
							<input type="date" {...bindPassportDate} placeholder="Дата выдачи" ref={r => inputs.passportDate = r} />
						</div>
						<div className="anketa-row">
							<InputMask mask="999-999" placeholder="Код подразеления" maskPlaceholder={null} {...bindPassportCode} ref={r => inputs.passportCode = r} />
						</div>
					</div>
				</> : null}
			{step === 2 ?
				<>
					<h3>Документы</h3>
					<div className="anketa-row-oneline">
						<div className="anketa-row">
							<InputMask mask="999999999999" placeholder="ИНН (12 цифр)" maskPlaceholder={null} {...bindINN} ref={r => inputs.INN = r} />
						</div>
						<div className="anketa-row">
							<InputMask mask="999-999-999-99" placeholder="СНИЛС (071-548-967-90)" maskPlaceholder={null} {...bindSNILS} ref={r => inputs.SNILS = r} />
						</div>
					</div>
					<h3>Адрес</h3>
					<div className="anketa-row">
						<input type="text" value={addressRegistration} placeholder="Адрес регистрации" ref={r => inputs.addressRegistration = r} onChange={(e) => addressCheck(e, setAddressRegistration, 'registartion')} />
						{addressReistrationList && addressReistrationList.length > 0 ?
							<div className="address-suggestions">
								{addressReistrationList.map((v,i) => <div key={i} onClick={() => addressSelect(v.result, setAddressRegistration)}>{v.result}</div>)}
							</div> : null}
					</div>
					<div className="anketa-row">
						<input type="text" value={addressLive} placeholder="Адрес регистрации" ref={r => inputs.addressLive = r} onChange={(e) => addressCheck(e, setAddressLive, 'live')} />
						{addressLiveList && addressLiveList.length > 0 ?
							<div className="address-suggestions">
								{addressLiveList.map((v,i) => <div key={i} onClick={() => addressSelect(v.result, setAddressLive)}>{v.result}</div>)}
							</div> : null}
					</div>
				</> : null}
			{step === 3 ?
				<>
					<h3>Ваш заработок</h3>
					<div className="anketa-row">
						<select onChange={selectHandleWorkType} value={workType}>
							<option>Выберите тип заработка</option>
							{userEmploymentName.map((v,i) => i !== userEmployment.UNSET ? <option key={i} value={i}>{v}</option> : null)}
						</select>
					</div>
					<div className="anketa-row">
						<input type="number" {...bindIncomeAverage} placeholder="Среднемесячный доход" />
					</div>
					<h3>Место работы</h3>
					<div className="anketa-row">
						<input type="text" {...bindWorkName} placeholder="Название оргранизации" />
					</div>
					<div className="anketa-row">
						<input type="text" {...bindWorkPosition} placeholder="Должность" />
					</div>
					<div className="anketa-row">
						<input type="text" {...bindWorkAddress} placeholder="Адрес организации" />
					</div>
					<div className="anketa-row">
						<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон организации" maskPlaceholder={null} {...bindWorkPhone} />
					</div>
				</> : null}
				{step === 4 ?
					<>
						<h3>Дополнительные контакты</h3>
						<div className="anketa-row">
							<InputMask mask={Array.from({length:30}, () => /^[а-яё]$/gi)} placeholder="Имя" maskPlaceholder={null} {...bindContactFirstName} />
						</div>
						<div className="anketa-row">
							<InputMask mask={Array.from({length:30}, () => /^[а-яё]$/gi)} placeholder="Фамилия" maskPlaceholder={null} {...bindContactLastName} />
						</div>
						<div className="anketa-row">
							<InputMask mask={Array.from({length:30}, () => /^[а-яё]$/gi)} placeholder="Отчество" maskPlaceholder={null} {...bindContactMiddleName} />
						</div>
						<div className="anketa-row">
							<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindContactPhone} />
						</div>
						<div className="anketa-row">
							<select onChange={selectHandleContactType} value={contactType}>
								<option>Кем приходится</option>
								{userContactTypeName.map((v,i) => i !== userContactType.UNSET ? <option key={i} value={i}>{v}</option> : null)}
							</select>
						</div>
					</> : null}
				{step === 5 ?
					<>
						<h3>Срок и сумма займа</h3>
						<div className="anketa-form-row">
							<span>{moneyFormat(amount, false)} ₽</span>
						</div>
						<div className="anketa-slider anketa-slider-separator">
							<RangeSlider
								id="rs-amount"
								min={amountMin}
								max={amountMax}
								step={SETTINGS.credit.amount.step}
								thumbsDisabled={[true,false]}
								rangeSlideDisabled={true}
								onInput={sliderAmountSet}
								defaultValue={[0,amount]} />
							<div className="anketa-slider-info">
								<span>{moneyFormat(amountMin, false)} ₽</span>
								<span>{moneyFormat(amountMax, false)} ₽</span>
							</div>
						</div>
						<div className="anketa-slider">
							<div className="anketa-slider-info">
								<span>На срок <b>{countsGet.days(period)}</b></span>
								<span>{moment().add(period, 'days').format('ll')}</span>
							</div>
							<RangeSlider
								id="rs-period"
								min={periodMin}
								max={periodMax}
								step={SETTINGS.credit.period.step}
								thumbsDisabled={[true,false]}
								rangeSlideDisabled={true}
								onInput={sliderPeriodSet}
								defaultValue={[0,period]} />
							<div className="anketa-slider-info">
								<span>{countsGet.days(periodMin)}</span>
								<span>{countsGet.days(periodMax)}</span>
							</div>
						</div>
						<h3>Способ получения</h3>
						<div className="anketa-row">
							<InputMask mask="9999 9999 9999 9999" placeholder="Номер карты для перевода" maskPlaceholder={null} {...bindCreditCardNumber} ref={r => inputs.creditCardNumber = r} />
						</div>
						<h3>Контактная информация</h3>
						<div className="anketa-row-oneline">
							<div className="anketa-row">
								<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Мобильный телефон" maskPlaceholder={null} {...bindPhone} ref={r => inputs.phone = r} />
							</div>
							<div className="anketa-row">
								<input type="email" {...bindEmail} placeholder="E-mail" pattern='\S+@\S+\.\S+' />
							</div>
						</div>
					</> : null}
				{step === 6 ?
					<>
						<h3>Фотографии паспорта</h3>
						<h4>Ваш портрет с паспортом</h4>
						<div className="anketa-passport-block">
							<div className="anketa-passport-block-left">
								<div className="anketa-passport-block-photo">
									<img src={require('./Images/passport/photo1.png')} alt="Фотография паспорта" />
								</div>
							</div>
							<div className="anketa-passport-block-right">
								<h4>Прилагаемая к заявлению фотография должна соответствовать следующим требованиям:</h4>
								<ul>
									<li>Четкое цветное изображение (желательно на однотонном фоне), мимика лица должна быть естественной, не искажающей черты лица.</li>
									<li>Лицо заявителя должно быть изображено полностью, в анфас, без головного убора, платка, солнечных или тёмных очков (кроме случаев, когда они необходимы по медицинским показаниям или по религиозным соображениям);</li>
									<li>Максимально допустимый размер файла - 2 Мб.</li>
								</ul>
								<div className="photo-block">
									<label>
										<div className="button-file">
											{photoPassportSelfie ? 'Изменить' : 'Добавить' }
											<input type="file" accept="image/jpeg,image/png" onChange={(e) => handlePhoto(e, 'selfie')} />
										</div>
									</label>
									{photoPassportSelfie ?
										<div className="photo-preview">
											<img src={photoPassportSelfie} alt="Фотография паспорта" />
										</div> : null}
								</div>
							</div>
						</div>
						<h4>Фото паспорта (основная страница)</h4>
						<div className="anketa-passport-block">
							<div className="anketa-passport-block-left">
								<div className="anketa-passport-block-photo">
									<img src={require('./Images/passport/photo2.png')} alt="Фотография с паспортом" />
								</div>
							</div>
							<div className="anketa-passport-block-right">
								<h4>Прилагаемая к заявлению скан копия/фотография паспорта должна соответствовать следующим требованиям:</h4>
								<ul>
									<li>На скан-копии/фотографии изображен паспорт главной страницы с фото</li>
									<li>Скан-копия/фотография паспорта должна быть четким, а текст читаемым</li>
									<li>Серия и номер паспорта должны быть хорошо видны на каждой странице</li>
									<li>Скан-копия/фотография с ксерокопии паспорта не допускается</li>
									<li>Максимально допустимый размер файла - 2 Мб.</li>
								</ul>
								<div className="photo-block">
									<label>
										<div className="button-file">
											{photoPassportFirst ? 'Изменить' : 'Добавить' }
											<input type="file" accept="image/jpeg,image/png" onChange={(e) => handlePhoto(e, 'passportFirst')} />
										</div>
									</label>
									{photoPassportFirst ?
										<div className="photo-preview">
											<img src={photoPassportFirst} alt="Фотография паспорта" />
										</div> : null}
								</div>
							</div>
						</div>
						<h4>Фото паспорта (страница с регистрацией)</h4>
						<div className="anketa-passport-block">
							<div className="anketa-passport-block-left">
								<div className="anketa-passport-block-photo">
									<img src={require('./Images/passport/photo3.png')} alt="Фотография паспорта" />
								</div>
							</div>
							<div className="anketa-passport-block-right">
								<h4>Прилагаемая к заявлению скан копия/фотография паспорта должна соответствовать следующим требованиям:</h4>
								<ul>
									<li>На скан-копии/фотографии изображен разворот паспорта с действующей регистрацией</li>
									<li>Скан-копия/фотография паспорта должна быть четким, а текст читаемым</li>
									<li>Серия и номер паспорта должны быть хорошо видны на каждой странице</li>
									<li>Скан-копия/фотография с ксерокопии паспорта не допускается</li>
									<li>Максимально допустимый размер файла - 2 Мб.</li>
								</ul>
								<div className="photo-block">
									<label>
										<div className="button-file">
											{photoPassportRegistration ? 'Изменить' : 'Добавить' }
											<input type="file" accept="image/jpeg,image/png" onChange={(e) => handlePhoto(e, 'registration')} />
										</div>
									</label>
									{photoPassportRegistration ?
										<div className="photo-preview">
											<img src={photoPassportRegistration} alt="Фотография паспорта" />
										</div> : null}
								</div>
							</div>
						</div>
						<h4>Фото банковской карты</h4>
						<div className="anketa-passport-block">
							<div className="anketa-passport-block-left">
								<div className="anketa-passport-block-photo">
									<img src={require('./Images/passport/photo4.png')} alt="Фотография паспорта" />
								</div>
							</div>
							<div className="anketa-passport-block-right">
								<h4>Прикрепите фотографию именной карты. Карта Momentum нами не обслуживается</h4>
								<div className="photo-block">
									<label>
										<div className="button-file">
											{photoCard ? 'Изменить' : 'Добавить' }
											<input type="file" accept="image/jpeg,image/png" onChange={(e) => handlePhoto(e, 'card')} />
										</div>
									</label>
									{photoCard ?
										<div className="photo-preview">
											<img src={photoCard} alt="Фотография карты" />
										</div> : null}
								</div>
							</div>
						</div>
						<h3>Соглашение</h3>
						<div className="anketa-row">
							<label>
								<input type="checkbox" onChange={checkHandleAgree} checked={agree} />
								Настоящим подтверждаю, что ознакомлен с <a href="/documenti/politika-konfidencialnosti" target="_blank">Согласием на обработку персональных данных</a>
							</label>
						</div>
					</> : null}
				{step === 7 ?
					<>
						<h3>Подтвердите заявку кодом из СМС</h3>
						<p>СМС-сообщение было отправленно на номер телефона <b>{phone}</b></p>
						<div className="anketa-row-oneline anketa-row-sms">
							<div className="anketa-row">
								<InputMask mask="999999" placeholder="СМС код" maskPlaceholder={null} {...bindSMSCode} ref={r => inputs.SMSCode = r} />
							</div>
						</div>
					</> : null}
				{step === 8 ?
						<div className="anketa-success">
							{isSend ?
									<>
										<h3>Идет отправка данных&hellip;</h3>
										<img src={loader} alt="Загрузка" />
									</>
								:
									<>
										<h3>Заявка отправлена!</h3>
										<p>Ваша заявка отправлена на рассмотрение и в ближайшее время по ней будет принято решение.</p>
										<p>Нажмите на кнопку ниже, чтобы перейти в личный кабинет для отслеживания статуса заявки.</p>
										<div className="anketa-success-button">
											<button onClick={() => toPersonal()}>Личный кабинет</button>
										</div>
									</>
							}
						</div>
					:
						<div className="anketa-controls">
							<div className="anketa-progress">
								<div className="anketa-steps">
									<div>
										<b>{step}</b> / {stepMax}
									</div>
									<div className="anketa-progress-bar">
										<div className="anketa-progress-bar-fill" style={{width: (step / stepMax * 100) + '%'}}></div>
									</div>
								</div>
							</div>
							<div className="anketa-buttons">
								{step > 1 ? <button className="back" onClick={() => prevSet(step-1)}>Назад</button> : null}
								<button className="next" onClick={() => nextSet(2)}>{step === 7 ? 'Отправить заявку' : 'Далее'}</button>
							</div>
						</div>
				}
			</div>
		</section>
	</Template>;
};

export default AnketaScreen;