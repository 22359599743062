import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// components
import Template from '../../Components/Template';

// styles
import './styles.css';

// data
const pagePrivaicy = <>
	<p>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую сайт Общества с ограниченной ответственностью микрокредитная компания «Капиталина» (далее – ООО МКК «Капиталина») расположенный на доменном имени www.capitalina.ru (а также его субдоменах), может получить о Пользователе во время использования сайта www.capitalina.ru (а также его субдоменов), его программ и его продуктов.</p>
	<p>1. Определение терминов</p>
	<p>1.1 В настоящей Политике конфиденциальности используются следующие термины:</p>
	<p>1.1.1. «Администрация сайта» (далее – Администрация) – уполномоченные сотрудники на управление сайтом ООО МКК «Капиталина» которые организуют и (или) осуществляют обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
	<p>1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных).</p>
	<p>1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
	<p>1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>
	<p>1.1.5. «Сайт ООО МКК «Капиталина» - это совокупность связанных между собой веб-страниц, размещенных в сети Интернет по уникальному адресу (URL) www.capitalina.ru, а также его субдоменах.</p>
	<p>1.1.6. «Субдомены» - это страницы или совокупность страниц, расположенные на доменах третьего уровня, принадлежащие сайту ООО МКК «Капиталина» а также другие временные страницы, внизу которой указана контактная информация Администрации</p>
	<p>1.1.5. «Пользователь сайта ООО МКК «Капиталина» (далее Пользователь) – лицо, имеющее доступ к сайту ООО МКК «Капиталина», посредством сети Интернет и использующее информацию, материалы и продукты сайта ООО МКК «Капиталина»</p>
	<p>1.1.7. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>
	<p>1.1.8. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, через который Пользователь получает доступ на МКК.</p>
	<p>2. Общие положения</p>
	<p>2.1. Использование сайта ООО МКК «Капиталина» Пользователем означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</p>
	<p>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта ООО МКК «Капиталина»</p>
	<p>2.3. Настоящая Политика конфиденциальности применяется к сайту ООО МКК «Капиталина». ООО МКК «Капиталина» не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте ООО МКК «Капиталина»</p>
	<p>2.4. Администрация не проверяет достоверность персональных данных, предоставляемых Пользователем.</p>
	<p>3. Предмет политики конфиденциальности</p>
	<p>3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации при регистрации на сайте ООО МКК «Капиталина» или при подписке на информационную e-mail рассылку.</p>
	<p>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения форм на сайте ООО МКК «Капиталина» и включают в себя следующую информацию:</p>
	<p>3.2.1. фамилию, имя, отчество Пользователя;</p>
	<p>3.2.2. контактный телефон Пользователя;</p>
	<p>3.2.3. адрес электронной почты (e-mail);</p>
	<p>3.2.4. место жительство Пользователя (при необходимости);</p>
	<p>3.2.5. фотографию (при необходимости);</p>
	<p>3.3. МКК защищает Данные, которые автоматически передаются при посещении страниц:</p>
	<p>- IP адрес;</p>
	<p>- информация из cookies;</p>
	<p>- информация о браузере;</p>
	<p>- время доступа;</p>
	<p>- реферер (адрес предыдущей страницы).</p>
	<p>3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта, требующим авторизации.</p>
	<p>3.3.2. ООО МКК «Капиталина» осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью предотвращения, выявления и решения технических проблем.</p>
	<p>3.4. Любая иная персональная информация неоговоренная выше (история посещения, используемые браузеры, операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. настоящей Политики конфиденциальности.</p>
	<p>4. Цели сбора персональной информации пользователя</p>
	<p>4.1. Персональные данные Пользователя Администрация может использовать в целях:</p>
	<p>4.1.1. Идентификации Пользователя, зарегистрированного на сайте ООО МКК «Капиталина» для его дальнейшей авторизации.</p>
	<p>4.1.2. Предоставления Пользователю доступа к персонализированным данным сайта ООО МКК «Капиталина»</p>
	<p>4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта ООО МКК «Капиталина», обработки запросов и заявок от Пользователя.</p>
	<p>4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>
	<p>4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</p>
	<p>4.1.6. Создания учетной записи для использования частей сайта ООО МКК «Капиталина», если Пользователь дал согласие на создание учетной записи.</p>
	<p>4.1.7. Уведомления Пользователя по электронной почте.</p>
	<p>4.1.8. Предоставления Пользователю эффективной технической поддержки при возникновении проблем, связанных с использованием сайта ООО МКК «Капиталина»</p>
	<p>4.1.9. Предоставления Пользователю с его согласия специальных предложений, новостной рассылки и иных сведений от имени сайта ООО МКК «Капиталина»</p>
	<p>5. Способы и сроки обработки персональной информации</p>
	<p>5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</p>
	<p>5.2. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.</p>
	<p>5.3. При утрате или разглашении персональных данных Администрация вправе не информировать Пользователя об утрате или разглашении персональных данных.</p>
	<p>5.4. Администрация принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
	<p>5.5. Администрация совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>
	<p>6. Права и обязанности сторон</p>
	<p>6.1. Пользователь вправе:</p>
	<p>6.1.1. Принимать свободное решение о предоставлении своих персональных данных, необходимых для использования сайта ООО МКК «Капиталина», и давать согласие на их обработку.</p>
	<p>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</p>
	<p>6.1.3. Пользователь имеет право на получение у Администрации информации, касающейся обработки его персональных данных, если такое право не ограничено в соответствии с федеральными законами. Пользователь вправе требовать от Администрации уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав. Для этого достаточно уведомить Администрацию по указанному e-mail адресу.</p>
	<p>6.2. Администрация обязана:</p>
	<p>6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</p>
	<p>6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. настоящей Политики Конфиденциальности.</p>
	<p>6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</p>
	<p>6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя, или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</p>
	<p>Ответственность сторон</p>
	<p>7.1. Администрация, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2. и 7.2. настоящей Политики Конфиденциальности.</p>
	<p>7.2. В случае утраты или разглашения Конфиденциальной информации Администрация не несёт ответственность, если данная конфиденциальная информация:</p>
	<p>7.2.1. Стала публичным достоянием до её утраты или разглашения.</p>
	<p>7.2.2. Была получена от третьей стороны до момента её получения Администрацией Ресурса.</p>
	<p>7.2.3. Была разглашена с согласия Пользователя.</p>
	<p>7.3. Пользователь несет полную ответственность за соблюдение требований законодательства РФ, в том числе законов о рекламе, о защите авторских и смежных прав, об охране товарных знаков и знаков обслуживания, но не ограничиваясь перечисленным, включая полную ответственность за содержание и форму материалов.</p>
	<p>7.4. Пользователь признает, что ответственность за любую информацию (в том числе, но не ограничиваясь: файлы с данными, тексты и т. д.), к которой он может иметь доступ как к части сайта ООО МКК «Капиталина», несет лицо, предоставившее такую информацию.</p>
	<p>7.5. Пользователь соглашается, что информация, предоставленная ему как часть сайта ООО МКК «Капиталина», может являться объектом интеллектуальной собственности, права на который защищены и принадлежат другим Пользователям, партнерам или рекламодателям, которые размещают такую информацию на сайте ООО МКК «Капиталина».</p>
	<p>Пользователь не вправе вносить изменения, передавать в аренду, передавать на условиях займа, продавать, распространять или создавать производные работы на основе такого Содержания (полностью или в части), за исключением случаев, когда такие действия были письменно прямо разрешены собственниками такого Содержания в соответствии с условиями отдельного соглашения.</p>
	<p>7.6. В отношение текстовых материалов (статей, публикаций, находящихся в свободном публичном доступе на сайте ООО МКК «Капиталина») допускается их распространение при условии, что будет дана ссылка на МКК.</p>
	<p>7.7. Администрация не несет ответственности перед Пользователем за любой убыток или ущерб, понесенный Пользователем в результате удаления, сбоя или невозможности сохранения какого-либо Содержания и иных коммуникационных данных, содержащихся на сайте ООО МКК «Капиталина» или передаваемых через него.</p>
	<p>7.8. Администрация не несет ответственности за любые прямые или косвенные убытки, произошедшие из-за: использования либо невозможности использования сайта, либо отдельных сервисов; несанкционированного доступа к коммуникациям Пользователя; заявления или поведение любого третьего лица на сайте.</p>
	<p>7.9. Администрация не несет ответственность за какую-либо информацию, размещенную пользователем на сайте ООО МКК «Капиталина», включая, но не ограничиваясь: информацию, защищенную авторским правом, без прямого согласия владельца авторского права.</p>
	<p>8. Разрешение споров</p>
	<p>8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и Администрацией, обязательным является предъявление претензии (письменного предложения или предложения в электронном виде о добровольном урегулировании спора).</p>
	<p>8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно или в электронном виде уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
	<p>8.3. При не достижении соглашения спор будет передан на рассмотрение Арбитражного суда г. Якутска</p>
	<p>8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией применяется действующее законодательство Российской Федерации.</p>
	<p>9. Дополнительные условия</p>
	<p>9.1. Администрация вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</p>
	<p>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на сайте ООО МКК «Капиталина», если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>
	<p>9.3. Все предложения или вопросы касательно настоящей Политики конфиденциальности следует сообщать по адресу: info@capitalina.ru</p>
</>;
const pageRekviziti = <>
	<p>Наименование компании полное в соответствии с учредительными документами:</p>
	<p>Общество с ограниченной ответственностью микрокредитная компания «Капиталина»</p>
	<p>Наименование компании сокращенное: ООО МКК «Капиталина»</p>
	<p>ИНН/КПП: 1656117090 / 165601001</p>
	<p>ОГРН: 1211600001821</p>
	<p>Адрес местонахождения: 420032, Татарстан Респ., Казань г, Лукницкого ул, дом № 2, офис 713.</p>
	<p>Регистрационный номер в реестре МФО 2103392009665</p>
	<p>СРО «Альянс» запись в реестре № 12 21 033 16 1626</p>
	<p>Банковские реквизиты:</p>
	<p>Расч./сч: 40701810062000000062</p>
	<p>Банк: ОТДЕЛЕНИЕ БАНК ТАТАРСТАН" N8610 ПАО СБЕРБАНК</p>
	<p>Корр./сч: 30101810600000000603</p>
	<p>БИК: 049205603</p>
</>;
const pageUslovia = <>
	<p>Процентная ставка от 0 до 0,8% в день (292% годовых)</p>
	<p>Минимальная сумма – 1 000 ₽</p>
	<p>Максимальная сумма — 30 000 ₽</p>
	<p>Максимальный срок – 31 день</p>
	<p>Необходимые документы для подачи заявления на получение потребительского займа:</p>
	<ul>
		<li>Паспорт Гражданина РФ</li>
		<li>Номер ИНН и СНИЛС</li>
		<li>Номер мобильного телефона и электронную почту</li>
		<li>Банковскую карту, оформленную на Ваше имя</li>
	</ul>
</>;
const pageFAQ = <>
	<h4>Как получить заем?</h4>
	<p>Для получения займа вам необходимо:</p>
	<ul>
		<li>Заполните анкету в личном кабинете: Приготовьте паспорт и СНИЛС для заполнения онлайн-анкеты. Дождитесь подтверждения идентификации</li>
		<li>Дождитесь одобрения заявки</li>
		<li>Получите деньги на свою карту: После положительного решения, мы переводим деньги на вашу карту сразу. В некоторых случаях процесс поступления может составлять всего несколько часов.</li>
	</ul>
	<h4>Какие условия выдачи займа?</h4>
	<p>Из основного — вам должно быть 18 лет или больше. Вам нужен паспорт гражданина РФ и СНИЛС. Правила предоставления займов, условия, типовой договор опубликованы на <a href="/documenti" target="_blank">capitalina.ru</a></p>
	<h4>Какие нужны документы?</h4>
	<p>Достаточно паспорта гражданина РФ и СНИЛС</p>
	<h4>Какие требования предъявляются к заемщику?</h4>
	<p>Ваша заявка с большой вероятностью будет одобрена, если: Ваш возраст не менее 18 лет; У вас есть российское гражданство; Вы правильно указали телефоны и информацию о себе в онлайн-анкете.</p>
	<h4>У меня были просрочки по займам. Могу ли я получить займ?</h4>
	<p>Каждая заявка рассматривается индивидуально. На решение по займу влияет множество факторов. Немаловажное значение имеет ваша кредитная история.</p>
	<h4>Нужны ли для получения займа залог или поручительство?</h4>
	<p>Мы выдаем займы без залога и поручительства.</p>
	<h4>Льготный период для мобилизованных и участников СВО</h4>
	<p>Основания для предоставления льготного периода по договору потребительского займа.</p>
	<p>Федеральный закон №377-ФЗ от 07.10.2022 года <a href="https://capitalina.ru/files/377.pdf" target="_blank">скачать</a>.</p>
	<p>Условия для получения льготного периода <a href="https://capitalina.ru/files/Mob%20(2).pdf" target="_blank">скачать</a>.</p>
</>;
const pageContacts = <>
	<h4>ООО МКК «Капиталина»</h4>
	<p><b>Почтовый адрес</b></p>
	<p>420032, г. Казань, а/я 572</p>
	<p><b>Адрес офисов</b></p>
	<p>420032, Татарстан Респ., Казань г, Лукницкого ул, дом № 2, офис 713.</p>
	<p>г. Кукмор, ул. Ленина, д. 9 а</p>
	<p>612920, город Малмыж, улица Урицкого, дом №3</p>
	<p><b>Телефон</b></p>
	<p>8 (800) 600-82-80</p>
	<p><b>Email</b></p>
	<p>info@capitalina.ru</p>
	<p><b>Режим работы</b></p>
	<p>09:00-18:00</p>
</>;

const links = [
	{link: 'uslovia', title: 'Индивидуальные условия', content:pageUslovia},
	{link: 'rekveziti', title: 'Реквизиты', content:pageRekviziti},
	{link: 'voprosi', title: 'Вопрос-ответ', content:pageFAQ},
	{link: 'kontakti', title: 'Контактная информация', content:pageContacts},
	{link: 'politika-konfidencialnosti', title: 'Политика конфиденциальности', content:pagePrivaicy}
];

const DocumentsScreen = (props) => {
	const [title, setTitle] = useState('Документы');
	const [page, setPage] = useState(null);
	const [link, setLink] = useState('uslovia');
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		dataGet();
		setLoading(false);
	}, []);
	useEffect(() => dataGet());
	const dataGet = () => {
		const link = props.match.params.link || 'uslovia';
		const page = links.find(item => item.link === link);
		if (!page) {
			window.location.href = '/404';
			return;
		}
		setLink(link);
		setTitle(page.title);
		setPage(page.content);
	};
	const isSelect = (l) => link === l ? 'selected' : '';
	return loading ? null : <Template bgStyle={{height:'300px'}}>
		<section className="documents-container">
			<h1>{title}</h1>
			<div className="documents-container-inner">
				<div className="documents-menu">
					<div className="documents-menu-title">Информация</div>
					<ul>
						<li className={isSelect('uslovia')}><Link to="/documenti/uslovia">Индивидуальные условия</Link></li>
						<li className={isSelect('rekveziti')}><Link to="/documenti/rekveziti">Реквизиты</Link></li>
						<li className={isSelect('voprosi')}><Link to="/documenti/voprosi">Вопрос-ответ</Link></li>
						<li className={isSelect('kontakti')}><Link to="/documenti/kontakti">Контактная информация</Link></li>
						<li className={isSelect('politika-konfidencialnosti')}><Link to="/documenti/politika-konfidencialnosti">Политика конфиденциальности</Link></li>
					</ul>
					<div className="documents-menu-title documents-menu-mobile">Свидетельства и документы</div>
					<ul className="documents-menu-mobile">
						<li><a target="_blank" href="/upload/information_system_2/1/5/4/item_154/%D0%9E%D0%B1%D1%89%D0%B8%D0%B5%20%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F%20%D0%B4%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%D0%B0%20%D0%BF%D0%BE%D1%82%D1%80%20%D0%B7%D0%B0%D0%B9%D0%BC%D0%B0%20%D0%B2%20%D0%9E%D0%9E%D0%9E%20%D0%9C%D0%9A%D0%9A%20%D0%9A%D0%B0%D0%BF%D0%B8%D1%82%D0%B0%D0%BB%D0%B8%D0%BD%D0%B0%2005.12.23.pdf">Общие условия предоставления потребительского займа</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/5/5/item_155/%D0%A1%D0%BF%D0%B8%D1%81%D0%BE%D0%BA%20%D0%BB%D0%B8%D1%86%20%D0%B4%D0%BB%D1%8F%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0%20%D0%BF%D0%BE%205470-%D0%A3%20%D0%B8%20%D0%9C%D0%A1%D0%A4%D0%9E%20%D0%BD%D0%B0%2007.2023.pdf">Список лиц, оказывающих существенное (прямое или косвенное) влияние на решения, принимаемые органами управления МКК</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/5/6/item_156/%D0%A3%D0%A1%D0%A2%D0%90%D0%92%20%20%D1%83%D0%B2%D0%B5%D0%BB%20%D1%83%D1%81%D1%82%20%D0%BA%D0%B0%D0%BF%D0%B8%D1%82%D0%B0%D0%BB%D0%B0%2014.06.23.pdf">Устав</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/5/7/item_157/list-zapisi-EGRUL.pdf">Лист записи ЕГРЮЛ</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/5/8/item_158/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F%20%D0%BE%D0%B1%20%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F%D1%85%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F%2C%20%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D0%B2%D0%BE%D0%B7%D0%B2%D1%80%D0%B0%D1%82%D0%B0%20%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D0%B7%D0%B0%D0%B9%D0%BC%D0%B0%20%D1%81%2001.07.2023.pdf">Информация об условиях предоставления, использования и возврата микрозайма</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/5/9/item_159/bazovy-standart-zashity-prav-i-interesov.pdf">Базовый стандарт защиты прав и интересов</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/6/0/item_160/politika-MKK.pdf">Политика МКК в отношении обработки и защиты персональных данных</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/6/1/item_161/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%20%20%D0%B7%D0%B0%D0%B9%D0%BC%D0%BE%D0%B2%20%D0%9C%D0%9A%D0%9A%20%D0%9A%D0%B0%D0%BF%D0%B8%D1%82%D0%B0%D0%BB%D0%B8%D0%BD%D0%B0%2024.10.2022.pdf">Правила предоставления потребительского займа</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/6/2/item_162/soglashenie-ob-ispolzovanii-prostoy-elektronnoy-podpisi.pdf">Соглашение об использовании простой электронной подписи</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/6/3/item_163/vyderzhki-iz-Federalnogo-zakona-ot-03.07.2016-230-FZ.pdf">Выдержки и Федерального закона от 03.07.2016 г. № 230-ФЗ</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/6/4/item_164/vypiska-iz-reestra-MFO.pdf">Выписка из реестра МФО</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/6/5/item_165/svidetelstvo-o-chlenstve-v-SRO.pdf">Свидетельство о членстве СРО</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/6/6/item_166/svidetelstvo-o-postanovke-MKK-na-uchet-v-nalogovom-organe.pdf">Свидетельство о постановке МКК на учет в налоговом органе</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/6/7/item_167/%D0%9A%D0%BB%D1%8E%D1%87%D0%B5%D0%B2%D0%BE%D0%B9%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B9%20%D0%B4%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%20%D0%BE%D0%B1%20%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F%D1%85%20%D0%B4%D0%BE%D0%B1%D1%80%D0%BE%D0%B2%D0%BE%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE%20%D1%81%D1%82%D1%80%D0%B0%D1%85%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F.pdf">Ключевой информационный документ об условиях добровольного страхования</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/7/9/item_179/_%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D1%81%D1%82%D1%80%D0%B0%D1%85%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%BA%20%D0%BF%D1%80%D0%B8%D0%BA%D0%B0%D0%B7%D1%83%20%281%29.pdf">Правила страхования к приказу</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/8/0/item_180/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D1%81%D1%82%D1%80%D0%B0%D1%85%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B6%D0%B8%D0%B7%D0%BD%D0%B8%20%D0%B8%20%D0%B7%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D1%8C%D1%8F%20%D0%BE%D1%82%20%D0%9D%D0%A1%20%D0%B8%20%D0%B1%D0%BE%D0%BB%D0%B5%D0%B7%D0%BD%D0%B5%D0%B9%207.0.%20%D0%BE%D1%82%2010.03.2023%20%281%29.pdf">Правила страхования жизни и здоровья от НС и болезней 7.0. от 10.03.2023</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/6/8/item_168/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F%20%D0%BE%20%D1%81%D1%82%D1%80%D0%B0%D1%85%D0%BE%D0%B2%D1%89%D0%B8%D0%BA%D0%B5%20%D0%B8%20%D1%88%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%D1%8B%20%D0%B4%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%BE%D0%B2.pdf">Информация о страховщике и шаблоны документов</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/7/3/item_173/%D0%A2%D0%B0%D1%80%D0%B8%D1%84%D1%8B%20%D0%BD%D0%B0%20%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0%D0%BD%D0%B8%D1%8F%20%D1%83%D1%81%D0%BB%D1%83%D0%B3%20%D0%9E%D0%9E%D0%9E%20%D0%9C%D0%9A%D0%9A%20%D0%9A%D0%B0%D0%BF%D0%B8%D1%82%D0%B0%D0%BB%D0%B8%D0%BD%D0%B0%2009.01.2024.pdf">Тарифы на оказания услуг</a></li>
						<li><a target="_blank" href="/upload/information_system_2/1/7/8/item_178/%D0%9E%D0%B1%D0%B7%D0%B0%D0%B7%D0%B5%D1%86%20%D0%97%D0%B0%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%BD%D0%B0%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%BA%D0%B0%D0%BD%D0%B8%D0%BA%D1%83%D0%BB%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%BD%D0%BE%20106-%D0%A4%D0%97.pdf">Обзазец Заявления на предоставление каникул согласно 106-ФЗ</a></li>
					</ul>
				</div>
				<div className="documents-content">
					{page}
				</div>
			</div>
		</section>
	</Template>;
};

export default DocumentsScreen;