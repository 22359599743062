import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const add	= async (data)		=> Http.request('ext/request', httpMethods.POST, data);

const check	= async (data)		=> Http.request('ext/request/check', httpMethods.POST, data);


export {
	add,
	check
}