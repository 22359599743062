const isDevelopmentMode = process.env.NODE_ENV !== 'production';

const url = {
	api			: isDevelopmentMode ? 'http://localhost:8090' : 'https://api.capitalina-mkk.ru',
	lk			: isDevelopmentMode ? 'http://localhost:3005' : 'https://lk.capitalina-mkk.ru'
};

const API = {
	url			: `${url.api}/api/v1/`,
	lk			: url.lk,
	lkAuth		: `${url.lk}/auth`,
	key			: 'J<DxyN+YT^$d_//=eq{^6?/@(FSd+5{:D,/BUcqAH]{Okl281.G>?rYf4?ar-KN',
	version		: '1.0.1'
};

const SETTINGS = {
	credit		: {
		amount: {
			min		: 1000,
			max		: 30000,
			step	: 1000,
			default	: 5000
		},
		period: {
			min		: 1,
			max		: 30,
			step	: 1,
			default	: 7
		},
		rate: .8
	}
};

const CONTACTS = {
	phone		: '8 (800) 600-82-80',
	email		: 'info@capitalina.ru',
	address		: ''
}

const httpMethods = {
	GET		: 'GET',
	POST	: 'POST',
	PUT		: 'PUT',
	DELETE	: 'DELETE'
};

const commonStatus = {
	IN_ACTIVE	: 0,
	ACTIVE		: 1
};
const commonStatusName = ['Не активен','Активен'];

/* Client */

const userGender = {
	UNSET		: 0,
	MALE		: 1,
	FEMALE		: 2
};
const userGenderName = ['','Мужской','Женский'];

const userEmployment = {
	UNSET				: 0,
	UNEMPLOYED			: 1,
	BUDGET				: 2,
	IP					: 3,
	OAO					: 4,
	WORKING_PENSIONER	: 5,
	SELF_EMPLOYED		: 6
};
const userEmploymentName = ['','Работающий','Безработный','Бюджетная организация','ИП','ОАО,ООО,ЗАО','Работающий пенсионер','Самозанятый'];

const userContactType = {
	UNSET		: 0,
	WIFE		: 1,
	CHILD		: 2,
	PARENT		: 3,
	RELATIVE	: 4,
	COLLEAGUE	: 5,
	FRIEND		: 6,
	OTHER		: 100
};
const userContactTypeName = ['','Жена/Муж','Ребенок','Родитель','Родственник','Коллега','Друг/Подруга','Другое'];


export {
	API,
	SETTINGS,
	CONTACTS,
	httpMethods,
	commonStatus,
	commonStatusName,
	userGender,
	userGenderName,
	userEmployment,
	userEmploymentName,
	userContactType,
	userContactTypeName
};