import React from 'react';
import {Link} from 'react-router-dom';

// helpers
import { phoneNormalize } from '../../Globals/Utils';

// globals
import { API, CONTACTS } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import logo from './Images/capitalina-logo.svg';
import logoblack from './Images/capitalina-logo-black.svg';
import phoneImage from "./Images/phone.svg";

const Template = (props) => {
	const toForm = () => {
		if (window.location.pathname === '/') window.scrollTo(0, 460);
		else window.location.href = '/';
	}
	return (
		<div className="wrapper">
			<div className="bg" style={props.bgStyle}></div>
			<header>
				<div className="container">
					<div className="container-inner">
						<div className="left-placeholder"></div>
						<Link to="/"><img src={logo} alt="ООО МКК  &quot;Капиталина&quot;" /></Link>
						<div className="header-links">
							<Link to="/documenti">Документация</Link>
							<a href={`tel:${phoneNormalize(CONTACTS.phone, true)}`}>{CONTACTS.phone}</a>
							<a href={API.lk} className="button">Личный кабинет</a>
						</div>
						<a href={`tel:${phoneNormalize(CONTACTS.phone, true)}`} className="phone-icon"><img src={phoneImage} alt="Позвонить" /></a>
					</div>
				</div>
			</header>
			<div className="container">
				{props.children}
			</div>
			<footer>
				<div className="container">
					<div className="container-inner">
						<div className="container-links">
						<Link to="/"><img src={logoblack} alt="ООО МКК &quot;Капиталина&quot;" /></Link>
							<div className="footer-links">
								<Link to="/documenti">Документация</Link>
								<a href={`tel:${phoneNormalize(CONTACTS.phone, true)}`}>{CONTACTS.phone}</a>
								<button className="button" onClick={() => toForm()}>Получить деньги</button>
							</div>
						</div>
						<p>ООО МКК «Капиталина» использует файлы «cookie», с целью персонализации сервисов и повышения удобства пользования веб-сайтом. «Cookie» представляют собой небольшие файлы, содержащие информацию о предыдущих посещениях веб-сайта. Если вы не хотите использовать файлы «cookie», измените настройки браузера.</p>
						<p>2021&ndash;2024 &copy; ООО МКК «Капиталина», официальный сайт, Регистрационный номер записи в государственном реестре микрофинансовых организаций ЦБ РФ № 2103392009665 от 23.03.2021 г.</p>
						<p className="requisites">
							ИНН 1656117090, ОГРН 1211600001821<br/>
							Юридический и фактический адрес:<br/>
							420032, Республика Татарстан (Татарстан), г.о. Город Казань, г Казань, ул Лукницкого, зд. 2, кабинет 713.<br/>
							Почтовый адрес: 420032, г. Казань, а/я 572<br/>
							info@capitalina.ru
						</p>
					</div>
				</div>
			</footer>
		</div>
	);
};
export default Template;