import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

// screens
import Start				from './Screens/Start';
import Documents			from './Screens/Documents';
import Anketa				from './Screens/Anketa';

import Error				from './Screens/Error';

// start
const App = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/' component={Start} exact />
			<Route path='/documenti' component={Documents} exact />
			<Route path='/documenti/:link' component={Documents} exact />
			<Route path='/anketa' component={Anketa} exact />

			<Route path='/500' component={Error} status={500} exact />
			<Route path='/401' component={Error} status={401} exact />
			<Route path='/403' component={Error} status={403} exact />
			<Route path='/404' component={Error} status={404} exact />
			<Route path='*' component={Error} status={404} />
		</Switch>
	</BrowserRouter>
);

export default App;